<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item to="growth_val">成长值配置</el-breadcrumb-item>
            <el-breadcrumb-item>RFM模型策略配置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row>
         <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
            <span>RFM模型策略配置</span>
         </el-link>
      </el-row>

      <el-row class="rfm-conf-box content-box">
         <el-row class="btn">
            <el-button class="bg-gradient" @click="showPopup = true">增加策略</el-button>
         </el-row>
         <el-row class="box">
            <el-col :span="8">
               <el-row class="box-title">
                  <el-col :span="11">最近一次消费（Recency）</el-col>
                  <el-col :span="11">对应成长值</el-col>
                  <el-col :span="2"></el-col>
               </el-row>
               <el-row class="box-form" v-for="(item, index) of recencyData" :key="index">
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.Key"></el-input>&nbsp;天以内
                  </el-col>
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.value"></el-input>
                  </el-col>
                  <el-col :span="2">
                     <i class="el-icon-delete" @click="delRecency(item.rid)"></i>
                  </el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row class="box-title">
                  <el-col :span="11">消费频率（Frequency）</el-col>
                  <el-col :span="11">对应成长值</el-col>
                  <el-col :span="2"></el-col>
               </el-row>
               <el-row class="box-form" v-for="(item, index) of frequencyData" :key="index">
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.Key"></el-input>&nbsp;次以内
                  </el-col>
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.value"></el-input>
                  </el-col>
                  <el-col :span="2">
                     <i class="el-icon-delete" @click="delFrequency(item.fid)"></i>
                  </el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row class="box-title">
                  <el-col :span="11">消费金额（Monetary）</el-col>
                  <el-col :span="11">对应成长值</el-col>
                  <el-col :span="2"></el-col>
               </el-row>
               <el-row class="box-form" v-for="(item, index) of monetaryData" :key="index">
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.Key"></el-input>&nbsp;元以内
                  </el-col>
                  <el-col :span="11">
                     <el-input class="width-90"  v-model="item.value"></el-input>
                  </el-col>
                  <el-col :span="2">
                     <i class="el-icon-delete" @click="delMonetary(item.mid)"></i>
                  </el-col>
               </el-row>
            </el-col>
         </el-row>
         <el-row class="m-top-15 m-bottom-30 box-btn">
            <el-button class="m-right-30" type="primary"  plain @click="handleCancel">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="handleSave"  v-text="$t('msg.save')">保存</el-button>
         </el-row>
      </el-row>

      <!-- 新增策略 -->
      <el-form>
         <el-dialog title="新增策略" :visible.sync="showPopup" width="400px">
            <el-row style="margin-top: 10%">新增类型：</el-row>
            <el-row style="margin-top: 5%">
               <el-checkbox-group v-model="checkList">
                  <el-checkbox label="R">最近消费</el-checkbox>
                  <el-checkbox label="F">消费频率</el-checkbox>
                  <el-checkbox label="M">消费金额</el-checkbox>
               </el-checkbox-group>
            </el-row>
            <div slot="footer" class="dialog-footer">
               <el-button @click="showPopup = false" >取 消</el-button>
               <el-button class="bg-gradient" type="primary" @click="addTactics" >确 定</el-button>
            </div>
         </el-dialog>
      </el-form>

   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data(){
         return{
            recencyData: [],
            frequencyData: [],
           hotelId:'',
            monetaryData: [],
            checkList: ['R', 'F', 'M'],
            weight: '',
            remark: 'RFM模型由最近一次消费（Recency）、消费频率（Frequency）、消费金额（Monetary）三个指标组成，' +
               '多用于传统营销、零售业等领域，是衡量客户价值和客户创利能力的重要工具和手段。',
            showPopup: false,
            rid: 0,
            fid: 0,
            mid: 0,
            /* 提示文本 */
            save_success: "保存成功！",
            name_no_null: '输入框不能为空！'
         }
      },
      mounted() {
         this.hotelId = this.$route.query.hotelId
         this.weight = this.$route.query.weight
         this.id = this.$route.query.id
         if (this.id) {
            let paramCfg = this.$route.query.paramCfg.split('|')
            this.getTacticsConf(paramCfg)
         }
      },
      methods: {
         // 获取策略配置
         getTacticsConf(paramCfg){
            paramCfg.forEach(item => {
               let itemCfg = JSON.parse(item)
               if (itemCfg.type === 'R'){
                  itemCfg.rid = this.rid += 1
                  this.recencyData.push(itemCfg)
               }
               if (itemCfg.type === 'F'){
                  itemCfg.fid = this.fid += 1
                  this.frequencyData.push(itemCfg)
               }
               if (itemCfg.type === 'M'){
                  itemCfg.mid = this.mid += 1
                  this.monetaryData.push(itemCfg)
               }
            })
         },
         // 增加策略
         addTactics(){
            if (this.checkList.includes('R')) this.recencyData.push({rid: this.rid += 1, type: 'R', Key: '', value: ''})
            if (this.checkList.includes('F')) this.frequencyData.push({fid: this.fid += 1, type: 'F', Key: '', value: ''})
            if (this.checkList.includes('M')) this.monetaryData.push({mid: this.mid += 1, type: 'M', Key: '', value: ''})
         },
         // 移除策略
         delRecency(rid){
            this.recencyData = this.recencyData.filter(item => {
               return item.rid !== rid
            })
         },
         // 移除活跃
         delFrequency(fid){
            this.frequencyData = this.frequencyData.filter(item => {
               return item.fid !== fid
            })
         },
         // 移除金额
         delMonetary(mid){
            this.monetaryData = this.monetaryData.filter(item => {
               return item.mid !== mid
            })
         },
         // 取消保存
         handleCancel(){},
         // 保存设置
         handleSave(){
            let paramCfgList = [...this.recencyData, ...this.frequencyData, ...this.monetaryData]
            // 过滤出没有填写正确配置的选项
            paramCfgList = paramCfgList.filter(item => {
               return item.Key !== '' || item.value !== ''
            }).map(item => {
               return JSON.stringify(item)
            })
            const url = urlObj.saveGrowConfig
            const param = {
               id: this.id,
               growType: 'RFM',
               weight: this.weight,
               remark: this.remark,
               hotelId: this.hotelId,
               isEnable: 'YES',
               paramCfgList
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.save_success,
                     type: 'success'
                  })
                  this.goBack()
               }
            })
         },
         // 返回上页
         goBack(){
            this.$router.go(-1)
         }
      }
   }
</script>

<style scoped lang="scss">
   .rfm-conf-box{

      .btn{
         text-align: right;
         margin-bottom: 10px;
      }
      .box{
         border: solid 1px #f6f6f6;
         font-size: 13px;
         text-align: center;
      }
      .box > div{
         border-right: solid 1px #f6f6f6;
      }
      .box-title{
         line-height: 38px;
         background-color: #f5f5f5;
      }
      .box-form{
         margin: 15px 0;
         i {
            line-height: 28px;
            margin-left: 10px;
         }
      }
      .box-btn{
         padding-left: 30px;
      }
   }
</style>

